import tuxedo1 from '../assets/traje1.jpg';
import tuxedo2 from '../assets/traje2.jpg';
import tuxedo3 from '../assets/traje3.jpg';
import tuxedo4 from '../assets/traje4.jpg';
import tuxedo5 from '../assets/traje5.png';
import tuxedo6 from '../assets/traje6.jpg';
import tuxedo7 from '../assets/traje7.jpg';
import tuxedo8 from '../assets/traje8.jpg';
import tuxedo9 from '../assets/traje9.jpg';
import tuxedo10 from '../assets/traje10.jpg';
import tuxedo11 from '../assets/traje11.jpg';
import tuxedo12 from '../assets/traje12.jpg';
import tuxedo13 from '../assets/traje13.jpg';
import tuxedo14 from '../assets/traje14.jpg';
import tuxedo15 from '../assets/traje15.jpg';
import tuxedo16 from '../assets/traje16.jpg';
import tuxedo17 from '../assets/traje17.jpg';
import tuxedo18 from '../assets/traje18.jpg';
import tuxedo19 from '../assets/traje19.jpg';
import tuxedo20 from '../assets/traje20.jpg';
import tuxedo21 from '../assets/traje21.jpg';
import tuxedo22 from '../assets/traje22.jpg';
import tuxedo23 from '../assets/traje23.jpg';
import tuxedo24 from '../assets/traje24.jpg';
import tuxedo25 from '../assets/traje25.jpg';
import tuxedo26 from '../assets/traje.26.jpg';
import tuxedo27 from '../assets/traje.27.jpg';


export const Tuxedos = [
  {
    productId: 26,
    name: 'Sand Suit',
    description: 'Experience sophistication with the Sand Suit. This tuxedo in a refined sand tone offers a modern and stylish look, perfect for any special occasion.',
    img: tuxedo26, 
    price: '199.00'
  },
  // {
  //   productId: 27,
  //   name: 'Glacier Grey Suit',
  //   description: 'Elevate your elegance with the Glacier Grey Suit. Featuring a sleek light grey color, this tuxedo is ideal for making a lasting impression.',
  //   img: tuxedo27, 
  //   price: '199.00'
  // },
  {
    top: 'topSale',
    productId: 1,
    name: 'Ultra Slim Cobalt Royal Blue Tribeca',
    description: 'Make a statement with the Cobalt Blue Tribeca at your next formal affair. The ultra slim fit styling is tailored to fit all body types - including big and tall sizes. The Tribeca is tailored in a soft, luxurious Super 120´s wool fabric. It features a black satin peak lapel, black satin double besom pockets and black satin covered buttons. Pair it with a pair of matching or black ultra slim fit pants for a trim and tapered styling.',
    img: tuxedo1,
    price: '199.00'
  },
  {
    top: 'topSale',
    productId: 2,
    name: 'Charcoal Grey Xavier',
    description: 'The Charcoal Grey "Xavier" by Ike Behar is sure to grab anyone´s attention; with it´s sleek design and eye catching black satin lapel. It has a 1-button front, satin peak lapel, satin besom pockets, and a self top collar, fashioned from a Super 100´s Wool. It´s slim, single breasted design makes it easy to capture your best assets when you´re trying to impress that special someone.',
    img: tuxedo2,
    price: '199.00'
  },
  {
    top: 'topSale',
    productId: 3,
    name: 'Black Jackson Slim Fit Tuxedo',
    description: 'The black Jackson slim tuxedo by Ike Behar is as classic and elegant and it is beautifully fitted.  Featuring a 1 button single breasted front, satin peak lapels, satin besom pockets, fashioned from luxuriously soft Super 120´s wool, and constructed for a slim fit, this is true black tie attire made specifically for those who want a trimmer and sleeker look for a special night.',
    img: tuxedo3,
    price: '199.00'
  },
  {
    top: 'topSale',
    productId: 4,
    name: 'Hendrix Tuxedo',
    description: 'With stretch capri pants',
    img: tuxedo4,
    price: '220.00'
  },
  {
    top: 'topSale',
    productId: 5,
    name: 'Ivory Shawl Lapel',
    description: 'The Charles Ivory Waverly Tuxedo by Ike Behar.',
    img: tuxedo5,
    price: '199.00'
  },
  {
    top: 'topSale',
    productId: 6,
    name: 'Ryan Suede Black and Gold Floral',
    description: 'Tuxedo with Capri Stretch Pants',
    img: tuxedo6,
    price: '230.00'
  },
  {
    productId: 7,
    name: 'Black Slim Fit',
    description: 'The Black Desire Tuxedo by Michael Kors is a modern fit tuxedo featuring a single-breasted two button front, satin notch lapels, satin besom pockets, side vents, and is fashioned from luxuriously soft Super 130´s Venetian Wool, for that extra touch of luxury and sophistication that Michael Kors is known for.',
    img: tuxedo7,
    price: '199.00'
  },
  {
    productId: 8,
    name: 'Owen Rose',
    description: 'Tuxedo by Ike Behar.',
    img: tuxedo8,
    price: '199.00'
  },
  {
    top: 'topSale',
    productId: 9,
    name: 'Navy Blue Tuxedo "Sebastian" by Ike Behar',
    description: 'The Navy Sebastian Tuxedo is a fantastic formal option for anyone looking for a clean and simple navy tuxedo.  Featuring a two button front, satin notch lapels, satin besom pockets, side vents, slim fit construction, and fashioned from luxuriously soft Super 120 s Wool, this tuxedo is a great way to stand apart from the crowd, while maintaining a timeless and elegant look!',
    img: tuxedo9,
    price: '199.00'
  },
  {
    top: 'topSale',
    productId: 10,
    name: 'Ultra Slim White Waverly',
    description: 'A modern twist on a classic tuxedo style, the Waverly tuxedo features a self shawl lapel edged in black satin and self-piped double besom pockets. Its ultra slim design is tailored to fit all body types - including big and tall sizes. Pair with a coordinating pair of ultra slim fit trousers for a classic dinner jacket look, or choose matching white pants.',
    img: tuxedo10,
    price: '199.00'
  },
  {
    productId: 11,
    name: 'Black Shawl Waverly',
    description: 'The Black Waverly Tuxedo by Ike Behar is one of the most elegant formal options we have to offer.  Similar to the Black Braydon Tuxedo by Ike Behar, it features a one button single-breasted front, shawl collar, satin besom pockets, and is fashioned from luxuriously soft Super 120´s wool in a slim cut for a closer, more exacting fit.  The primary difference is that the ´Waverly´ has a self lapel with satin trim.  This tuxedo is a beautiful formal option for any special evening.',
    img: tuxedo11,
    price: '199.00'
  },
  {
    productId: 12,
    name: 'White Clayton',
    description: 'It´s hard to be missed when you´re wearing a white tuxedo!  Add in a black shawl lapel & I guarantee you won´t go unnoticed.  The ´Clayton´ features a one button front with a black shawl collar and is slim cut for a closer, more exacting fit.  This tuxedo is a sure bet for garnering wanted attention at any black tie or special event.',
    img: tuxedo12,
    price: '199.00'
  },
  {
    top: 'topSale',
    productId: 13,
    name: 'Black "Chase" Paisley',
    description: 'The new “Chase” Paisley Tuxedo line is an attention grabbing black tie formal favorite!  Featuring a single button front, black satin peak lapels, black satin besom pockets, side vents, slim fit construction, and fashioned from luxuriously soft BLACK Super 120’s Wool, this tuxedo is not only striking and powerful.',
    img: tuxedo13,
    price: '199.00'
  },
  {
    top: 'topSale',
    productId: 14,
    name: 'Burgundy "Chase"',
    description: 'The new “Chase” Paisley  Tuxedo line is an attention grabbing BURGUNDY PAISLEY formal favorite!  Featuring a single button front, black satin peak lapels, black satin besom pockets, side vents, slim fit construction, and fashioned from luxuriously soft burgundy Super 120’s Wool, this tuxedo is not only striking and powerful, it’s also the most formal of our burgundy tuxedo options!',
    img: tuxedo14,
    price: '199.00'
  },
  {
    productId: 15,
    name: 'Dark Grey Slim',
    description: 'The Steel Grey ´Bartlett´ Tuxedo by Allure Men was expertly and aptly created in response to the growing demand for quality grey tuxedos. The notch lapels on the coat are self material with a thin satin indented trim around the edges. Other features include a single-breasted, two button front, satin besom pockets and side vents. This fashionable and elegant crowd pleaser is perfect for your wedding, prom, or other formal function that calls for a modern take on style and class!',
    img: tuxedo15,
    price: '199.00'
  },
  {
    productId: 16,
    name: 'Red Carmine',
    description: 'It´s hard to be missed when you´re wearing a red tuxedo!    The ´Carmine´ features a one button front with a shawl collar and is slim cut for a closer, more exacting fit.  This tuxedo is a sure bet for garnering wanted attention at any prom or special event.',
    img: tuxedo16,
    price: '230.00'
  },
  //{
    //top: 'topSale',
    //productId: 17,
    //name: 'Tan Tuxedo',
    //description: 'The Tan ´Bartlett´ Tuxedo by Allure Men is a powerful and popular style in this incredible line of wedding tuxedos!  While wonderful for a wide variety of formal and semi-formal events, the Tan ´Bartlett´ Tuxedo caters most directly to daytime garden and destination weddings.  The tuxedo actually contains no satin. The lapels are self material with self trim. Other features include a two button front and side vents. This style is perfect when you need to look positively dapper, without looking overly formal.  This style is an elegant crowd-pleaser!',
    //img: tuxedo17,
    //price: '199.00'
  //},
  //{
    //top: 'topSale',
    //productId: 18,
    //name: 'Heather Light Grey Bartlett',
    //description: 'He Heather Grey ´Bartlett´ Tuxedo by Allure Men was expertly and aptly created in response to the growing demand for quality grey tuxedos. The notch lapels on the coat are self material with a thin satin indented trim around the edges. Other features include a single-breasted, two button front, satin besom pockets and side vents. This fashionable and elegant crowd pleaser is perfect for your wedding, prom, or other formal function that calls for a modern take on style and class!',
    //img: tuxedo18,
    //price: '199.00'
  //},
  {
    productId: 19,
    name: 'Dusk Blue Tuxedo "LANE"',
    description: 'Looking for a fresh new shade of Blue? Look no further than the Dusk Blue ‘Lane’ Tuxedo by Ike Behar. Constructed of luxuriously soft Super 120’s Wool & features a Slim Fit, 1 button front, satin edge lapel with side vents. This elegant new shade of blue is perfect for you next formal event.',
    img: tuxedo19,
    price: '199.00'
  },
  {
    productId: 20,
    name: 'Burgundy Marbella',
    description: 'n response to red carpet looks and sharp dressing professional sportsmen, Ike Behar® has made the burgundy ´Marbella´ slim fit tuxedo!   It features contrasting black satin peak lapels and a one button front and is made from luxurious super 120´s wool.Matching trousers and vest are available, if you dare!',
    img: tuxedo20,
    price: '199.00'
  },
  {
    top: 'topSale',
    productId: 21,
    name: 'Green "Chase" Paisley',
    description: 'The new “Chase” Paisley  Tuxedo line is an attention grabbing HUNTER GREEN tux favorite!  Featuring a single button front, black satin peak lapels, black satin besom pockets, side vents, slim fit construction, and fashioned from luxuriously soft green Super 120’s Wool, this tuxedo is not only striking and powerful, it’s also the most formal of our green tuxedo options!',
    img: tuxedo21,
    price: '199.00'
  },
  {
    productId: 22,
    name: 'Navy Blue Hudson',
    description: 'The Navy ´Hudson´ Tuxedo is perfect for formal events and weddings!  It features a single button front, black satin shawl lapel, black satin besom pockets, side vents, slim fit construction, and fashioned from luxuriously soft navy Super 120´s Wool, this tuxedo is not only striking and powerful, it´s also the newest of our navy tuxedo options! Don´t forget to check out the Sebastian and BlakeTuxedos, as well as  the Collin suit, which are all part of the same navy Ike Behar family.',
    img: tuxedo22,
    price: '199.00'
  },
  {
    productId: 23,
    name: 'Navy "Chase" Tuxedo',
    description: 'The new “Chase” Paisley  Tuxedo line is an attention grabbing navy formal favorite!  Featuring a single button front, NAVY satin peak lapels, black satin besom pockets, side vents, slim fit construction, and fashioned from luxuriously soft navy Super 120’s Wool, this tuxedo is not only striking and powerful, it’s also the most formal of our navy tuxedo options!',
    img: tuxedo23,
    price: '230.00'
  },
  {
    productId: 24,
    name: 'David Major Platinum Grey',
    description: 'The David Major Platinum Grey Suit features a 2 button closure with a self-trimmed self-notch lapel. Other features include self-flap pockets side vents. Both the coat and pants are slim fit and constructed pout of stretch poly viscose.',
    img: tuxedo25,
    price: '230.00'
  },
  {
    productId: 25,
    name: 'Ike Behar Cream  "Dominic" Stretch',
    description: 'The Ike Behar Cream "Dominic" Suit features a 2 button closure with a notch lapel. Other features include flap pockets and side vents. Both the coat and pants are slim fit and constructed out of a Stretch poly/wool spandex fabric.',
    img: tuxedo24, 
    price: '230.00'
  },
]