import '../styles/popup.scss';
import { useState, useEffect } from 'react';

export const PopUpMessage = () => {
  const [ popUp, setPopUp ]  = useState(false);

  useEffect( () => {
    setTimeout(
      () => setPopUp(true),
      5000
    )
  }, []) 

  const close = () => setPopUp(false)
 
  return (
    <>{popUp ? 
      <div className='pop-up'>
        <div className='pop-up-box'>
          <div className='img-pop-up'></div>
          <div className='content-pop-up'>
            <div className='pop-up-btn'>
              <button onClick={close}>X</button>
            </div>
            <div className='text-pop-up'>
  <span>Prom Season is Here! </span>
  <h3>• Get the perfect tux for your prom night.</h3>
  {/* <h3>• Special discounts on early rentals!</h3> */}
  <span className='anexo'> Book your tux today!</span>
</div>
          </div>
        </div>
      </div> 
    : null}
    </>
  )
}
